.comp1 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    margin: 0px;
    padding: 0;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    list-style: none;
    list-style-position: initial;
    list-style-image: initial;
    list-style-type: none;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/navigation-bg.gif");
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: repeat-x;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
    width: 920px;
    height: 40px;
}

.comp1_1 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: disc;
    list-style: disc;
    box-sizing: border-box;
    float: left;
    width: 184px;
    height: 40px;
    background: none;
    background-image: none;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
}

.comp1_2 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: disc;
    list-style: disc;
    box-sizing: border-box;
    float: left;
    width: 184px;
    height: 40px;
    background-position-x: 0px;
    background-position-y: 0px;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/navigation-separator.gif");
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: no-repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp1_3 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: disc;
    list-style: disc;
    box-sizing: border-box;
    float: left;
    width: 184px;
    height: 40px;
    background-position-x: 0px;
    background-position-y: 0px;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/navigation-separator.gif");
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: no-repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp1_4 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: disc;
    list-style: disc;
    box-sizing: border-box;
    float: left;
    width: 184px;
    height: 40px;
    background-position-x: 0px;
    background-position-y: 0px;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/navigation-separator.gif");
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: no-repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp1_5 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: disc;
    list-style: disc;
    box-sizing: border-box;
    float: left;
    width: 184px;
    height: 40px;
    background-position-x: 0px;
    background-position-y: 0px;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/navigation-separator.gif");
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: no-repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp1_6 {
    font-family: 'Merriweather Sans', sans-serif;
    color: #fff;
    font-size: 15px;
    line-height: 40px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: disc;
    list-style: disc;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
    display: block;
    height: 40px!important;
    text-align: center;
    text-shadow: 1px 1px 2px #000;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    font-weight: bold;
    outline-color: initial;
    outline-style: none;
    outline-width: initial;
}

.comp1_7 {
    font-family: 'Merriweather Sans', sans-serif;
    color: #fff;
    font-size: 15px;
    line-height: 40px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: disc;
    list-style: disc;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
    display: block;
    height: 40px!important;
    text-align: center;
    text-shadow: 1px 1px 2px #000;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    font-weight: bold;
    outline-color: initial;
    outline-style: none;
    outline-width: initial;
}

.comp1_8 {
    font-family: 'Merriweather Sans', sans-serif;
    color: #fff;
    font-size: 15px;
    line-height: 40px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: disc;
    list-style: disc;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
    display: block;
    height: 40px!important;
    text-align: center;
    text-shadow: 1px 1px 2px #000;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    font-weight: bold;
    outline-color: initial;
    outline-style: none;
    outline-width: initial;
}

.comp1_9 {
    font-family: 'Merriweather Sans', sans-serif;
    color: #fff;
    font-size: 15px;
    line-height: 40px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: disc;
    list-style: disc;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
    display: block;
    height: 40px!important;
    text-align: center;
    text-shadow: 1px 1px 2px #000;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    font-weight: bold;
    outline-color: initial;
    outline-style: none;
    outline-width: initial;
}

.comp1_10 {
    font-family: 'Merriweather Sans', sans-serif;
    color: #fff;
    font-size: 15px;
    line-height: 40px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: disc;
    list-style: disc;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
    display: block;
    height: 40px!important;
    text-align: center;
    text-shadow: 1px 1px 2px #000;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    font-weight: bold;
    outline-color: initial;
    outline-style: none;
    outline-width: initial;
}

