.comp14 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    letter-spacing: normal;
    box-sizing: content-box;
    position: relative;
    overflow: hidden;
    padding: 0px 0px 0px 0px;
    height: 310px;
    border: 0px solid RGBA(62,62,62,1);
    border-radius: 0px;
    background-clip: border-box;
    background-repeat: repeat;
    background-position: 0% 0%;
    background-size: auto;
    background-attachment: scroll;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(62, 62, 62);
    border-right-color: rgb(62, 62, 62);
    border-bottom-color: rgb(62, 62, 62);
    border-left-color: rgb(62, 62, 62);
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    background-repeat-x: repeat;
    background-repeat-y: repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-color: rgb(255, 255, 255);
    background-image: none;
    background-origin: padding-box;
    width: 940px;
    margin: 0px;
}

.comp14_1 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    letter-spacing: normal;
    box-sizing: content-box;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 1px solid rgba(0,0,0,0);
    z-index: 10;
    overflow-x: hidden;
    overflow-y: hidden;
    outline-color: rgba(0, 0, 0, 0);
    outline-style: solid;
    outline-width: 1px;
}

.comp14_2 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    letter-spacing: normal;
    box-sizing: content-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.comp14_3 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: normal;
    box-sizing: content-box;
    position: absolute;
    overflow: hidden!important;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    top: 0;
    left: 0;
    width: 940px;
    height: 310px;
    z-index: 20;
    display: block;
    backface-visibility: hidden;
    perspective: 1500px;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -100000, 0, 0, 1);
}

.comp14_4 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    letter-spacing: normal;
    box-sizing: content-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, -100000, 0, 0, 1);
}

.comp14_5 {
    font-family: 'Merriweather Sans', sans-serif !important;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: border-box;
    outline: 1px solid RGBA(0,0,0,0);
    z-index: 2;
    outline-color: rgba(0, 0, 0, 0);
    outline-style: solid;
    outline-width: 1px;
    flex: 0 0 auto;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 1px;
    display: flex;
    justify-content: center;
    flex-flow: column;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    flex-direction: column;
    flex-wrap: initial;
    padding: 0px 0px 0px 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
}

.comp14_6 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    letter-spacing: normal;
    box-sizing: content-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.comp14_7 {
    font-family: 'Merriweather Sans', sans-serif !important;
    color: #707070;
    font-size: 100%;
    line-height: 26px;
    text-align: left;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
    outline: 1px solid RGBA(0,0,0,0);
    z-index: 2;
    outline-color: rgba(0, 0, 0, 0);
    outline-style: solid;
    outline-width: 1px;
    position: absolute;
    display: flex;
    flex: 1 1 auto;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    left: 18px;
    top: 20px;
    width: 462px;
    height: 250px;
    overflow: visible;
    right: auto;
    bottom: auto;
    overflow-x: visible;
    overflow-y: visible;
}

.comp14_8 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    letter-spacing: normal;
    box-sizing: content-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position-x: 50%;
    background-position-y: 50%;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("https://rist.ro/wp-content/uploads/2018/09/neuroni.jpg");
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp14_9 {
    font-family: 'Merriweather Sans', sans-serif !important;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: border-box;
    display: flex;
    height: auto;
    width: 100%;
    padding: 1.25em 0.625em 0em 0.625em;
    padding-top: 1.25em;
    padding-right: 0.625em;
    padding-bottom: 0em;
    padding-left: 0.625em;
}

.comp14_10 {
    font-family: 'Merriweather Sans', sans-serif !important;
    color: #707070;
    font-size: 100%;
    line-height: 26px;
    text-align: left;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
    outline: 1px solid RGBA(0,0,0,0);
    z-index: 2;
    outline-color: rgba(0, 0, 0, 0);
    outline-style: solid;
    outline-width: 1px;
    position: relative;
    min-height: 1px;
    display: flex;
    flex-flow: column;
    order: 10;
    flex: 1 1 auto;
    flex-direction: column;
    flex-wrap: initial;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    width: 100%;
    margin: 10px;
    overflow: visible;
    margin-right: 10px;
    margin-top: 10px;
    overflow-x: visible;
    overflow-y: visible;
    margin-bottom: 10px;
    margin-left: 10px;
}

.comp14_11 {
    font-family: 'Merriweather Sans', sans-serif !important;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: border-box;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: relative;
    min-height: 10px;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    width: 100%;
    height: auto;
    transition: all .3s;
    transition-property: border,background-image,background-color,border-radius,box-shadow;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;
    background-position-x: initial;
    background-position-y: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    padding: 0.625em 0em 0em 0.9375em;
    padding-top: 0.625em;
    padding-right: 0em;
    padding-bottom: 0em;
    padding-left: 0.9375em;
    background-color: rgba(255, 255, 255, 0.76);
    background-image: none;
    background-position: 0% 0%;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp14_12 {
    font-family: 'Merriweather Sans', sans-serif !important;
    color: #707070;
    font-size: 100%;
    line-height: 26px;
    text-align: left;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
    outline: 1px solid RGBA(0,0,0,0);
    z-index: 2;
    outline-color: rgba(0, 0, 0, 0);
    outline-style: solid;
    outline-width: 1px;
    position: relative;
    min-height: 1px;
    width: 100%;
    flex: 0 0 auto;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    align-self: flex-start;
    max-width: 300px;
    margin: 0em;
    overflow: visible;
    margin-top: 0em;
    margin-right: 0em;
    margin-bottom: 0em;
    margin-left: 0em;
    overflow-x: visible;
    overflow-y: visible;
}

.comp14_13 {
    font-family: 'Merriweather Sans', sans-serif !important;
    color: #707070;
    font-size: 100%;
    line-height: 26px;
    text-align: left;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
    outline: 1px solid RGBA(0,0,0,0);
    z-index: 2;
    outline-color: rgba(0, 0, 0, 0);
    outline-style: solid;
    outline-width: 1px;
    position: relative;
    min-height: 1px;
    width: 100%;
    flex: 0 0 auto;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    align-self: flex-start;
    max-width: 600px;
    margin: 0em;
    overflow: visible;
    margin-top: 0em;
    margin-right: 0em;
    margin-bottom: 0em;
    margin-left: 0em;
    overflow-x: visible;
    overflow-y: visible;
}

.comp14_14 {
    font-family: 'Merriweather Sans', sans-serif !important;
    color: #707070;
    font-size: 100%;
    line-height: 26px;
    text-align: left;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
    outline: 1px solid RGBA(0,0,0,0);
    z-index: 2;
    outline-color: rgba(0, 0, 0, 0);
    outline-style: solid;
    outline-width: 1px;
    position: relative;
    min-height: 1px;
    width: auto;
    flex: 0 0 auto;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    max-width: 100%;
    margin: 0em;
    overflow: visible;
    margin-top: 0em;
    margin-right: 0em;
    margin-bottom: 0em;
    margin-left: 0em;
    overflow-x: visible;
    overflow-y: visible;
}

.comp14_15 {
    font-family: 'Merriweather Sans','sans-serif';
    color: #00445e;
    font-size: 237.5%;
    line-height: normal;
    text-align: left;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
    text-shadow: none;
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
    word-spacing: normal;
    text-transform: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
    opacity: 1;
    padding: 0px 0px 0px 0px;
    box-shadow: none;
    border-width: 0px;
    border-style: solid;
    border-color: RGBA(0,0,0,1);
    border-radius: 0px;
    background-position-x: initial;
    background-position-y: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(0, 0, 0);
    border-right-color: rgb(0, 0, 0);
    border-bottom-color: rgb(0, 0, 0);
    border-left-color: rgb(0, 0, 0);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    display: block;
    white-space: nowrap;
    background-color: rgba(255, 255, 255, 0);
    background-image: none;
    background-position: 0% 0%;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp14_16 {
    font-family: 'Merriweather Sans', sans-serif !important;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
}

.comp14_17 {
    font-family: 'Merriweather Sans', sans-serif !important;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
    display: inline-block;
    white-space: nowrap;
}

.comp14_18 {
    font-family: 'Merriweather Sans', sans-serif !important;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: left;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
}

.comp14_19 {
    font-family: ubuntubold,Arial,sans-serif;
    color: #ffffff;
    font-size: 17px;
    line-height: 38px;
    text-align: center;
    letter-spacing: normal;
    visibility: visible;
    white-space: nowrap;
    box-sizing: content-box;
    background-color: #005d80;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
    border: 1px solid #363636;
    hyphens: none;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(54, 54, 54);
    border-right-color: rgb(54, 54, 54);
    border-bottom-color: rgb(54, 54, 54);
    border-left-color: rgb(54, 54, 54);
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    outline: 0!important;
    transition: none 0s;
    box-shadow: none;
    transition-duration: 0s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: none;
    outline-color: initial !important;
    outline-style: initial !important;
    outline-width: 0px !important;
    background: #005d80;
    opacity: 1;
    padding: 10px 30px 10px 30px;
    border-width: 0px;
    border-style: solid;
    border-color: RGBA(0,0,0,1);
    border-radius: 0px;
    background-image: url('https://rist.ro/fileadmin/templates/images/buttonify-bg.gifif') 0 0 repeat-x;
    padding-top: 0px;
    padding-right: 25px;
    padding-bottom: 0px;
    padding-left: 25px;
    height: 38px;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    display: inline-block;
    text-shadow: 1px 1px 2px #000;
    font-weight: bold;
    font-style: normal;
    word-spacing: normal;
    text-transform: uppercase;
}

.comp14_20 {
    font-family: 'Open Sans','sans-serif';
    color: #6c6c6c;
    font-size: 112.5%;
    line-height: 27px;
    text-align: left;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
    margin: 0;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    height: auto;
    width: auto;
    border: 0;
    box-shadow: none;
    hyphens: none;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(0, 0, 0);
    border-right-color: rgb(0, 0, 0);
    border-bottom-color: rgb(0, 0, 0);
    border-left-color: rgb(0, 0, 0);
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    transition: none 0s;
    transition-duration: 0s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: none;
    opacity: 1;
    padding: 0px 0px 0px 0px;
    border-width: 0px;
    border-style: solid;
    border-color: RGBA(0,0,0,1);
    border-radius: 0px;
    background-position-x: initial;
    background-position-y: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    padding-top: 0px;
    padding-bottom: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    text-shadow: none;
    font-weight: bold;
    font-style: normal;
    text-decoration: none;
    word-spacing: normal;
    text-transform: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
    background-color: rgba(255, 255, 255, 0);
    background-image: none;
    background-position: 0% 0%;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp14_21 {
    font-family: ubuntubold, Arial, sans-serif;
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 38px;
    text-align: center;
    letter-spacing: normal;
    visibility: visible;
    white-space: nowrap;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    word-spacing: normal;
    box-sizing: content-box;
    display: inline-flex;
    text-decoration: none solid rgb(255, 255, 255);
    text-decoration-line: none;
    text-decoration-style: solid;
    text-decoration-color: rgb(255, 255, 255);
    align-items: center;
    vertical-align: top;
}

.comp14_22 {
    font-family: ubuntubold, Arial, sans-serif;
    color: rgb(255, 255, 255);
    font-size: 17px;
    line-height: 38px;
    text-align: center;
    letter-spacing: normal;
    visibility: visible;
    white-space: nowrap;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    word-spacing: normal;
    box-sizing: content-box;
    display: inline;
    text-decoration: none solid rgb(255, 255, 255);
    text-decoration-line: none;
    text-decoration-style: solid;
    text-decoration-color: rgb(255, 255, 255);
}

