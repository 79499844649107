.comp13 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    box-sizing: border-box;
    margin: 0px;
    background-color: rgb(85, 85, 85);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/footer-bg.gif");
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
    width: 293px;
    height: 40px;
}

.comp13_1 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
}

