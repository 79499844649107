.comp10 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    box-sizing: border-box;
    width: 313px;
    min-width: 313px;
    background-color: rgb(85, 85, 85);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/footer-bg.gif");
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
    height: 141px;
    margin: 0px;
}

.comp10_1 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 40px;
}

.comp10_2 {
    font-family: 'Merriweather Sans', sans-serif;
    color: #fff;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    clear: both;
    margin: 0;
    text-rendering: optimizeLegibility;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-position-x: 0px;
    background-position-y: bottom;
    background-repeat-x: repeat;
    background-repeat-y: no-repeat;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/footer-separator.png");
    background-position: 0px 100%;
    background-size: auto;
    background-repeat: repeat-x;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp10_3 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    box-sizing: border-box;
    position: relative;
}

.comp10_4 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    box-sizing: border-box;
}

.comp10_5 {
    font-family: Montserrat, "Helvetica Neue", sans-serif;
    color: #fff;
    font-size: 16px;
    line-height: 1;
    box-sizing: border-box;
    font: inherit;
    margin: 0;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: 700;
    font-stretch: 100%;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    max-width: 100%;
    cursor: pointer;
    appearance: button;
    border: 0;
    border-radius: 0 2px 2px 0;
    letter-spacing: 0.046875em;
    padding: 0.84375em 0.875em 0.78125em;
    text-transform: uppercase;
    background-position-x: initial;
    background-position-y: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: initial;
    border-right-style: initial;
    border-bottom-style: initial;
    border-left-style: initial;
    border-top-color: initial;
    border-right-color: initial;
    border-bottom-color: initial;
    border-left-color: initial;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-top-left-radius: 0px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0px;
    padding-top: 0;
    padding-right: 0.875em;
    padding-bottom: 0.78125em;
    padding-left: 0.875em;
    bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 42px;
    background-color: rgb(26, 26, 26);
    background-image: none;
    background-position: 0% 0%;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp10_6 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #686868;
    font-size: 13px;
    line-height: normal;
    box-sizing: border-box;
    font: inherit;
    margin: 0;
    font-style: normal;
    font-variant-ligatures: normal;
    font-variant-caps: normal;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    font-weight: 400;
    font-stretch: 100%;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    max-width: 100%;
    appearance: textfield;
    border: 1px solid #d1d1d1;
    border-radius: 2px 0 0 2px;
    padding: 0.625em 0.4375em;
    width: calc(100% - 42px);
    background-position-x: initial;
    background-position-y: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(209, 209, 209);
    border-right-color: rgb(209, 209, 209);
    border-bottom-color: rgb(209, 209, 209);
    border-left-color: rgb(209, 209, 209);
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-top-left-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 2px;
    padding-top: 0.625em;
    padding-right: 0.4375em;
    padding-bottom: 0.625em;
    padding-left: 0.4375em;
    background-color: rgb(247, 247, 247);
    background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
    background-position: 0% 0%;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

