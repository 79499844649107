ul.ComponentsList {
  display: inline-flex;
  list-style-type: none;
  overflow-x: auto; overflow-y: hidden; width: 100%;
}

div.CompContainer{
  border: 1px dashed red;
  margin: 2px;
 }
