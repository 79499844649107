.comp7 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    display: block;
    padding: 0 7.6923%;
    height: 120px;
    padding-top: 0px;
    padding-right: 7.6923%;
    padding-bottom: 0px;
    padding-left: 7.6923%;
    background-position-x: initial;
    background-position-y: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    width: 1903px;
    margin: 0px;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/html-bg.gif");
    background-position: 0% 0%;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp7_1 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    max-width: 940px;
    margin: 0 auto;
    background: url('https://rist.ro/wp-content/uploads/2018/09/header-bg-4.gif') no-repeat scroll 0 0;
    position: relative;
    height: 100px;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    background-image: url("https://rist.ro/wp-content/uploads/2018/09/header-bg-4.gif");
    background-position-x: 0px;
    background-position-y: 0px;
    background-size: initial;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-attachment: scroll;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
}

.comp7_2 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    top: 34px;
    position: absolute;
    right: 0px;
}

.comp7_3 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    max-width: 70%;
    min-width: 0;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
    margin: 0 auto 0 0;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: 0px;
}

.comp7_4 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    list-style-type: none;
}

.comp7_5 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #007baa;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
    display: block;
}

.comp7_6 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 13px;
    line-height: 26px;
    list-style-type: none;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
}

.comp7_7 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #007baa;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    border: 0;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: initial;
    border-right-style: initial;
    border-bottom-style: initial;
    border-left-style: initial;
    border-top-color: initial;
    border-right-color: initial;
    border-bottom-color: initial;
    border-left-color: initial;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    height: auto;
    max-width: 100%;
    padding-top: 20px;
}

