.comp3_1 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 0.8125rem;
    line-height: 1.6153846154;
    box-sizing: border-box;
    display: block;
    min-width: 260px;
    border: 1px solid #d0d0d0;
    padding: 20px 20px 5px;
    background-position-x: initial;
    background-position-y: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(208, 208, 208);
    border-right-color: rgb(208, 208, 208);
    border-bottom-color: rgb(208, 208, 208);
    border-left-color: rgb(208, 208, 208);
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    padding-top: 1.615384615em;
    padding-right: 20px;
    padding-bottom: 5px;
    padding-left: 20px;
    width: 260px;
    height: 783px;
    margin: 0px;
    background-color: rgb(235, 235, 235);
    background-image: none;
    background-position: 0% 0%;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp3_1_1 {
    font-family: 'Merriweather Sans', sans-serif;
    color: #00445e;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    clear: both;
    margin: 0;
    text-rendering: optimizeLegibility;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 1.3125em;
    margin-left: 0px;
    position: relative;
    text-indent: 32px;
}

.comp3_1_2 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 13px;
    line-height: 20px;
    box-sizing: border-box;
    margin-bottom: 0;
}

.comp3_1_3 {
}

.comp3_1_4 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 13px;
    line-height: 20px;
    box-sizing: border-box;
    margin: 0 0 20px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 1.6153846154em;
    margin-left: 0px;
}

.comp3_1_5 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 13px;
    line-height: 20px;
    box-sizing: border-box;
    margin: 0 0 20px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 1.6153846154em;
    margin-left: 0px;
}

.comp3_1_6 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 13px;
    line-height: 20px;
    box-sizing: border-box;
    margin: 0 0 1.75em 1.25em;
    padding: 0;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 1.6153846154em;
    margin-left: 1.25em;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    list-style: disc;
    list-style-position: initial;
    list-style-image: initial;
    list-style-type: disc;
}

.comp3_1_7 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 13px;
    line-height: 20px;
    box-sizing: border-box;
    margin: 0 0 20px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 1.6153846154em;
    margin-left: 0px;
}

.comp3_1_8 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 13px;
    line-height: 20px;
    box-sizing: border-box;
    margin: 0 0 20px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0;
    margin-left: 0px;
}

.comp3_1_9 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 13px;
    line-height: 20px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: disc;
    list-style: disc;
    box-sizing: border-box;
}

.comp3_1_10 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 13px;
    line-height: 20px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: disc;
    list-style: disc;
    box-sizing: border-box;
}

.comp3_1_11 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #007baa;
    font-size: 13px;
    line-height: 20px;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
}

