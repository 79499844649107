.comp4_1 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    display: block;
    position: relative;
    background-color: rgb(255, 255, 255);
    background-image: none;
    background-position: 0% 0%;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
    width: 658px;
    height: 179px;
    margin: 0px;
}

.comp4_1_1 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    padding-bottom: 14px;
    margin-bottom: 19px;
    min-height: 160px;
    background-position-x: 0px;
    background-position-y: bottom;
    background-repeat-x: repeat;
    background-repeat-y: no-repeat;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/dotted-separator.png");
    background-position: 0px 100%;
    background-size: auto;
    background-repeat: repeat-x;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp4_1_2 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    float: left;
    margin-right: 20px;
}

.comp4_1_3 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
}

.comp4_1_4 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #007baa;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
    display: block;
    margin-right: 0;
    margin-left: 0;
}

.comp4_1_5 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    display: block;
    margin-right: 0;
    margin-left: 0;
}

.comp4_1_6 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    border-color: #d1d1d1;
    border-top-color: rgb(209, 209, 209);
    border-right-color: rgb(209, 209, 209);
    border-bottom-color: rgb(209, 209, 209);
    border-left-color: rgb(209, 209, 209);
    margin-right: 0;
    margin-left: 0;
    margin-top: -0.913043478em;
}

.comp4_1_7 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #007baa;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    border: 1px solid #cacaca;
    border-top-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(202, 202, 202);
    border-right-color: rgb(202, 202, 202);
    border-bottom-color: rgb(202, 202, 202);
    border-left-color: rgb(202, 202, 202);
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    height: auto;
    max-width: 100%;
    display: block;
}

.comp4_1_8 {
    font-family: 'Merriweather Sans', sans-serif;
    color: #007baa;
    font-size: 20px;
    line-height: 1.225;
    box-sizing: border-box;
    clear: both;
    margin: 0;
    text-rendering: optimizeLegibility;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 1.05em;
    margin-left: 0px;
    padding-top: 0;
    hyphens: auto;
}

.comp4_1_9 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    margin: 0 0 20px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 1.5217391304em;
    margin-left: 0px;
}

.comp4_1_10 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    text-align: left;
    display: block;
    padding-top: 10px;
    margin-bottom: 0;
}

.comp4_1_11 {
}

.comp4_1_12 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #007baa;
    font-size: 16px;
    line-height: 17px;
    text-align: left;
    box-sizing: border-box;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
    display: block;
    float: left;
    padding: 0 0 0 12px;
    min-height: 8px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 12px;
    background-position-x: left;
    background-position-y: center;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/more-arrow.png");
    background-position: 0% 50%;
    background-size: auto;
    background-repeat: no-repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

