.comp11 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    background-image: none;
    background-position: 0% 0%;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
    width: 658px;
    height: 26px;
    margin: 0px;
}

.comp11_1 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    margin: 0 0 20px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
}

.comp11_2 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #007baa;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
}

