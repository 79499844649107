.comp6 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    opacity: 0.7;
    padding: 0px 0px 0px 0px;
    box-shadow: none;
    border-width: 0px;
    border-style: solid;
    border-color: RGBA(0,0,0,1);
    border-radius: 0px;
    background-position-x: initial;
    background-position-y: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(0, 0, 0);
    border-right-color: rgb(0, 0, 0);
    border-bottom-color: rgb(0, 0, 0);
    border-left-color: rgb(0, 0, 0);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 72px;
    height: 18px;
    margin: 0px;
    background-color: rgb(255, 255, 255);
    background-image: none;
    background-position: 0% 0%;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp6_1 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
}

.comp6_2 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
}

.comp6_3 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
}

.comp6_4 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
}

.comp6_5 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
    opacity: 0.5;
    padding: 5px 5px 5px 5px;
    box-shadow: none;
    border-width: 0px;
    border-style: solid;
    border-color: RGBA(0,93,128,1);
    border-radius: 50px;
    margin: 4px;
    background-position-x: initial;
    background-position-y: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(0, 93, 128);
    border-right-color: rgb(0, 93, 128);
    border-bottom-color: rgb(0, 93, 128);
    border-left-color: rgb(0, 93, 128);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    margin-top: 4px;
    margin-right: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
    cursor: pointer;
    transition: background-color 0.4s;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: background-color;
    background-color: rgb(0, 68, 94);
    background-image: none;
    background-position: 0% 0%;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp6_6 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
    opacity: 0.5;
    padding: 5px 5px 5px 5px;
    box-shadow: none;
    border-width: 0px;
    border-style: solid;
    border-color: RGBA(0,93,128,1);
    border-radius: 50px;
    margin: 4px;
    background-position-x: initial;
    background-position-y: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(0, 93, 128);
    border-right-color: rgb(0, 93, 128);
    border-bottom-color: rgb(0, 93, 128);
    border-left-color: rgb(0, 93, 128);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    margin-top: 4px;
    margin-right: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
    cursor: pointer;
    transition: background-color 0.4s;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: background-color;
    background-color: rgb(0, 0, 0);
    background-image: none;
    background-position: 0% 0%;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp6_7 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
    opacity: 1;
    padding: 5px 5px 5px 5px;
    box-shadow: none;
    border-width: 0px;
    border-style: solid;
    border-color: RGBA(0,93,128,1);
    border-radius: 50px;
    margin: 4px;
    background-position-x: initial;
    background-position-y: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(0, 93, 128);
    border-right-color: rgb(0, 93, 128);
    border-bottom-color: rgb(0, 93, 128);
    border-left-color: rgb(0, 93, 128);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    margin-top: 4px;
    margin-right: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
    cursor: default;
    transition: background-color 0.4s;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: background-color;
    background-color: rgb(0, 0, 0);
    background-image: none;
    background-position: 0% 0%;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp6_8 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #707070;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: normal;
    visibility: visible;
    box-sizing: content-box;
    opacity: 0.5;
    padding: 5px 5px 5px 5px;
    box-shadow: none;
    border-width: 0px;
    border-style: solid;
    border-color: RGBA(0,93,128,1);
    border-radius: 50px;
    margin: 4px;
    background-position-x: initial;
    background-position-y: initial;
    background-repeat-x: initial;
    background-repeat-y: initial;
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: rgb(0, 93, 128);
    border-right-color: rgb(0, 93, 128);
    border-bottom-color: rgb(0, 93, 128);
    border-left-color: rgb(0, 93, 128);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    margin-top: 4px;
    margin-right: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
    cursor: pointer;
    transition: background-color 0.4s;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: background-color;
    background-color: rgb(0, 0, 0);
    background-image: none;
    background-position: 0% 0%;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

