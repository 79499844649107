div.pfSlot.overlay {
    position: relative;
}

div.pfSlot.overlay>div.SlotContent {
    visibility: hidden;
}

div.pfSlot.overlay>div.SlotOverlay {
    /*    width: this.state.width + px;
    height: this.state.height + px;*/
    background-color: rgba(255, 255, 255, 0.0);
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.pfSlot.overlay>div.SlotOverlay>span {
    border: 1px dotted rgba(128, 128, 128, 0.5);;
    width: 50%;
    text-align: center;
}

div.pfSlot>div.SlotOverlay {
    display: none;
}
