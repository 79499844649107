.comp9 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    box-sizing: border-box;
    width: 313px;
    min-width: 313px;
    background-color: rgb(85, 85, 85);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/footer-bg.gif");
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
    height: 107px;
    margin: 0px;
}

.comp9_1 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    box-sizing: border-box;
    padding-right: 20px;
}

.comp9_2 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    box-sizing: border-box;
}

.comp9_3 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    box-sizing: border-box;
}

.comp9_4 {
    font-family: 'Merriweather Sans', sans-serif;
    color: #fff;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    clear: both;
    margin: 0;
    text-rendering: optimizeLegibility;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-position-x: 0px;
    background-position-y: bottom;
    background-repeat-x: repeat;
    background-repeat-y: no-repeat;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/footer-separator.png");
    background-position: 0px 100%;
    background-size: auto;
    background-repeat: repeat-x;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp9_5 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    box-sizing: border-box;
    margin: 0 0 1.75em 1.25em;
    padding: 0;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 1.75em;
    margin-left: 1.25em;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    list-style: disc;
    list-style-position: initial;
    list-style-image: initial;
    list-style-type: disc;
}

.comp9_6 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: disc;
    list-style: disc;
    box-sizing: border-box;
}

.comp9_7 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: disc;
    list-style: disc;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
}

