.comp8 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 13px;
    box-sizing: border-box;
    padding-bottom: 20px;
    background-color: rgb(85, 85, 85);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/footer-bg.gif");
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
    width: 939px;
    height: 20px;
    margin: 0px;
}

.comp8_1 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 13px;
    box-sizing: border-box;
    float: left;
}

