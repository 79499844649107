.comp5 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    box-sizing: border-box;
    margin: 0px;
    padding: 0;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 1px;
    padding-left: 0px;
    list-style: disc;
    list-style-position: initial;
    list-style-image: initial;
    list-style-type: disc;
    background-color: rgb(85, 85, 85);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/footer-bg.gif");
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
    width: 120px;
    height: 24px;
}

.comp5_1 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: none;
    list-style: disc;
    box-sizing: border-box;
    float: left;
    width: 24px;
    height: 24px;
    margin-right: 6px;
}

.comp5_2 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: none;
    list-style: disc;
    box-sizing: border-box;
    float: left;
    width: 24px;
    height: 24px;
    margin-right: 6px;
}

.comp5_3 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: none;
    list-style: disc;
    box-sizing: border-box;
    float: left;
    width: 24px;
    height: 24px;
    margin-right: 6px;
}

.comp5_4 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: none;
    list-style: disc;
    box-sizing: border-box;
    float: left;
    width: 24px;
    height: 24px;
    margin-right: 6px;
}

.comp5_5 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: none;
    list-style: disc;
    box-sizing: border-box;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
    display: block;
    width: 24px;
    height: 24px;
    text-indent: -9999em;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-position-x: 0px;
    background-position-y: 0px;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/social-icons.png");
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: no-repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp5_6 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: none;
    list-style: disc;
    box-sizing: border-box;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
    display: block;
    width: 24px;
    height: 24px;
    text-indent: -9999em;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-position-x: -24px;
    background-position-y: 0px;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/social-icons.png");
    background-position: -24px 0px;
    background-size: auto;
    background-repeat: no-repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp5_7 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: none;
    list-style: disc;
    box-sizing: border-box;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
    display: block;
    width: 24px;
    height: 24px;
    text-indent: -9999em;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-position-x: -48px;
    background-position-y: 0px;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/social-icons.png");
    background-position: -48px 0px;
    background-size: auto;
    background-repeat: no-repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

.comp5_8 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: none;
    list-style: disc;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none;
    text-decoration-line: none;
    text-decoration-style: initial;
    text-decoration-color: initial;
    display: block;
    width: 24px;
    height: 24px;
}

.comp5_9 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #007baa;
    font-size: 12px;
    line-height: 20px;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: none;
    list-style: disc;
    box-sizing: border-box;
    border: 0;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: initial;
    border-right-style: initial;
    border-bottom-style: initial;
    border-left-style: initial;
    border-top-color: initial;
    border-right-color: initial;
    border-bottom-color: initial;
    border-left-color: initial;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    height: auto;
    max-width: 100%;
}

