div.GridItem {
    outline: 1px dashed rgba(255, 0, 0, .25);
    zzoverflow: hidden; /* testing: toggle this to see how components want more vertical space */
}

.inner { /* cpu intensive */
    display: block;
    width: 100%;
    xmin-height: 100%;  /* testing */
    margin: 0px 0px 0px 0px;
}

.layout.preview .inner {
    outline: 0px;
    margin: 0px 0px 0px 0px;
    width: 100%;
    xheight: 100%;  /* testing */
}
.layout.preview div.GridItem {
    outline: 0px;
}
.layout.preview span.tooltip,
.layout.preview div.colorBox,
.layout.preview .react-resizable-handle
 {
    display: none;
}

span.tooltip {
    position: absolute;
    left: 50%;
    top: 0%;
    transform: translate(-50%,0%);
    font-family: sans-serif;
    font-size: xx-small;
    color: white;
    z-index: 1001;
    border: 1px dotted rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 0, 0, 1);
    cursor: grabbing;
    user-select: none;
    opacity: 1;
}

div.colorBox {
    position: absolute;
    z-index: 99;
    width: 100%;
    min-height: 100%;
    background-color: red;
    opacity: 0.75;
    zzzheight: auto; /* ??? */
}

div.zoomOut {
    transform: scale(0.5) translate(-50%, -50%);
    width: 200%;
    height: 200%;
    overflow: hidden;  /* testing */
}

.react-grid-item>.react-resizable-handle {
    z-index: 1001;
}

.hide-button {
    z-index: 1001;
    cursor: pointer;
    position: absolute;
    font-size: 20px;
    top: 0px;
    right: 5px;
  }
  