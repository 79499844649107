.comp12 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    box-sizing: border-box;
    background-color: rgb(85, 85, 85);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/footer-bg.gif");
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
    width: 293px;
    height: 46px;
    margin: 0px;
}

.comp12_1 {
    font-family: 'Merriweather Sans', sans-serif;
    color: #fff;
    font-size: 16px;
    line-height: 26px;
    box-sizing: border-box;
    clear: both;
    margin: 0;
    text-rendering: optimizeLegibility;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-position-x: 0px;
    background-position-y: bottom;
    background-repeat-x: repeat;
    background-repeat-y: no-repeat;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("https://rist.ro/wp-content/themes/rist/images/footer-separator.png");
    background-position: 0px 100%;
    background-size: auto;
    background-repeat: repeat-x;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
}

