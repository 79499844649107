.two-col-layout {
  display: flex;
}
  
.two-col-layout.column1 {
  flex: 75%;
}
.two-col-layout.column2 {
    flex: 25%;
}

.three-col-layout {
    display: flex;
}
    
.three-col-layout.column1 {
  flex: 33%;
}
.three-col-layout.column2 {
    flex: 33%;
}
.three-col-layout.column2 {
    flex: 33%;
}

body {
    overflow-x: hidden;
}

